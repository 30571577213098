@font-face {
  font-family: 'SF Pro Text';
  src: url('./assets/fonts/SF-Pro-Text/SF-Pro-Text-Light.ttf');
  font-display: swap;
  font-weight: 400;
}

@font-face {
  font-family: 'SF Pro Text';
  src: url('./assets/fonts/SF-Pro-Text/sf-pro-text-medium.woff') format('woff');
  font-display: swap;
  font-weight: 500;
}

@font-face {
  font-family: 'SF Pro Text';
  src: url('./assets/fonts/SF-Pro-Text/SF-Pro-Text-Medium.ttf');
  font-display: swap;
  font-weight: 600;
}

@font-face {
  font-family: 'SF Pro Text';
  src: url('./assets/fonts/SF-Pro-Text/sf-pro-text-bold.woff') format('woff');
  font-display: swap;
  font-weight: 700;
}

@font-face {
  font-family: 'SF Pro Text';
  src: url('./assets/fonts/SF-Pro-Text/SF-Pro-Text-Regular.otf');
  font-display: swap;
}

* {
  font-family: system-ui, sans-serif;
  box-sizing: border-box;
}

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, sub, sup, tt, var,
b, u, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}

html, body {
  height: 100%;
  font-family: 'SF Pro Text', system-ui, sans-serif;
}

body {
  line-height: 1;
}

:root {
  --reviews-io-widget-star-color: #EB731A;
  --reviews-io-widget-star-color-disabled: rgba(235, 115, 26, 0.5);
  --reviews-io-widget-header-star-color-disabled: rgba(32, 43, 71, 0.5);
  --reviews-io-widget-text-color: #010101;
  --reviews-io-widget-background-color: #FFFFFF;
}

ol, ul {
  list-style: none;
}

svg .weightGraphicPath {
  stroke-dasharray: 600;
  stroke-dashoffset: 0;
  animation: weightGraphicDashAnimation 2s both;
}

@keyframes weightGraphicDashAnimation {
  from {
    stroke-dashoffset: 600;
  }
  to {
    stroke-dashoffset: 0;
  }
}

@keyframes opacityAnimation {
  0%    { opacity: 0; }
  100%  { opacity: 1; }
}

/* @description: Hack for Paywall - for "OR" text */
div[class^="divider"], #primerio-container {
  font-family: 'SF Pro Text', serif;
}

@keyframes coachLoadingSpinner {
  to {
    transform: rotate(360deg);
  }
}
